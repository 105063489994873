/**
 * @file standalone/content_block.scss
 *
 * \brief Library file - shared styles for content blocks
 *
 */

// 2019 Redesign
// New library for content blocks that use mantle components.
// Lib is attached per template as needed.

@import '../theme-bootstrap';

.content-block {
  display: block;
  font-family: $helvetica-regular-font-family;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
  &[data-clickable],
  [data-clickable] {
    cursor: pointer;
  }
  b,
  strong {
    font-family: $helvetica-bold-font-family;
    font-weight: bold;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__content-wrapper {
    width: 100%;
    height: 100%;
    &--positioned {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
    }
  }
  &__line {
    // make sure we do not pick up flex alignment rules
    display: block;
    font-size: 17px; // testing this as a default
    padding-bottom: 12px;
    @media #{$cr19-large-up} {
      padding-bottom: 16px;
    }
    @media #{$cr19-xlarge-up} {
      font-size: 19px;
    }
    &.content-block__line--eyebrow {
      .title--small {
        font-family: $nitti-regular-font-family;
      }
    }
    // compensate for the WYSIWYG adding extra paragraphs
    p {
      margin: 0;
      padding: 0;
      .content-block--split-width-tout & {
        padding-bottom: 12px;
        @media #{$cr19-large-up} {
          padding-bottom: 16px;
        }
      }
    }
  }
  &__links {
    display: block;
    font-size: 17px; // testing this as a default
    min-height: 1.5em;
    padding-bottom: 12px;
    @media #{$cr19-large-up} {
      padding-bottom: 16px;
    }
    &--2up {
      > div:first-of-type {
        margin-bottom: 20px;
      }
      @media #{$cr19-large-up} {
        display: flex;
        flex-direction: row;
        > div:first-of-type {
          margin-bottom: 0;
          margin-#{$rdirection}: 20px;
        }
      }
    }
    .basic-tout & {
      padding-bottom: 0;
      @media #{$cr19-large-up} {
        padding-bottom: 0;
      }
    }
    &.content-block__icons {
      a {
        margin-top: 5px;
        display: inline-block;
      }
    }
    .content-block__cta-icon {
      width: 30px;
      height: 30px;
      display: inline-block;
      margin-#{$rdirection}: 5px;
      vertical-align: top;
    }
  }
  // Adding for the cases where we are not using mantle media.
  // We need to make sure we fill containers.
  &__picture {
    &-media-asset {
      width: 100%;
      height: 100%;
      img,
      picture {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }
    .basic-tout & {
      padding-bottom: 21px;
      @media #{$cr19-large-up} {
        padding-bottom: 24px;
      }
    }
    .basic-tout__text-over-image {
      display: flex;
      flex-direction: column;
      height: 80%;
      width: 100%;
    }
    .content-block {
      &__image {
        display: block;
      }
      &__image--alt {
        display: none;
      }
    }
  }
  // basic tout is shared - defaults needed here
  &.basic-tout {
    // Typography defaults when none are selected in cms
    .content-block__line {
      padding-bottom: 0;
      @media #{$cr19-large-up} {
        padding-bottom: 0;
      }
      &--eyebrow {
        .style--bare {
          @include text-body-text--tags;
        }
      }
      &--header {
        .title--small {
          font-family: $helvetica-bold-font-family;
        }
      }
      &--headline {
        .style--bare {
          @include text-title--small;
        }
      }
      &--content {
        .style--bare {
          @include text-body-text;
        }
      }
    }
    &__text-over-image-text {
      .style--bare {
        @include text-title--med;
      }
    }
  }
  &__override-color {
    &.hero-full-width__content-wrapper--mobile {
      .text-color {
        color: $cr19-text;
        &--black {
          color: $cr19-text-black;
          p {
            color: $cr19-text-black;
          }
        }
        &--white {
          color: $cr19-text-white;
          p {
            color: $cr19-text-white;
          }
        }
        &--grey {
          color: $cr19-text-grey;
          p {
            color: $cr19-text-grey;
          }
        }
      }
    }
  }
  // General overrides
  .elc-button-default {
    color: $cr19-text;
    font-family: $helvetica-regular-font-family;
    &:hover,
    &:visited {
      color: $cr19-text;
    }
  }
  .basic-tout__inner {
    &--has-img-alt:hover {
      .content-block {
        &__image {
          @media #{$cr19-large-up} {
            display: none;
          }
        }
        &__image--alt {
          @media #{$cr19-large-up} {
            display: block;
          }
        }
      }
    }
  }
}
